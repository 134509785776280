import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Layout from '@components/layout'
import SEO from '@components/seo'
import Footer from '@components/Footer/Footer'
// import LifePreserver from "@static/icons/life-preserver.svg'

export const query = graphql`
  query NotFoundQuery($id: Int) {
    strapiNotfound(strapiId: { eq: $id }) {
      strapiId
      title
      subtitle
      content
      description
    }
  }
`

const NotFoundPage = ({ data }) => {
  const notfound = data.strapiNotfound
  const title = notfound.title ? notfound.title : ''
  const description = notfound.description ? notfound.description : ''
  const subtitle = notfound.subtitle ? notfound.subtitle : ''
  const content = notfound.content ? notfound.content : ''
  // const returnLabel = notfound.returnLabel ? notfound.returnLabel : ''

  return (
    <Layout>
      <main className='grid-content'>
        <div className="grid-content-inner">
          <div id='lost-at-sea' className='grid-container page'>
            <SEO
              title={title}
              description={description}
            />
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
            <ReactMarkdown
              children={content}
            />

          <p className="center">
              <AniLink
                fade
                to='/'
                className='return-to'
              >
                Return to the main page
              </AniLink>
            </p>
          </div>
        </div>
        <Footer />
      </main>
    </Layout>
  )
}

export default NotFoundPage
